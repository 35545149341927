import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { PatientDTO } from "../../dto/patient";
import { format } from "date-fns";

interface PatientTableProps {
  patients: Array<PatientDTO>;
}

interface Column {
  id: "name" | "birthDate" | "gender" | "athenaid";
  label: string;
  minWidth?: number;
  align?: "right";
}

const columns: Column[] = [
  { id: "name", label: "Name", minWidth: 50 },
  { id: "gender", label: "Gender", minWidth: 50 },
  { id: "birthDate", label: "DOB", minWidth: 10 },
  { id: "athenaid", label: "athenaid", minWidth: 50 },
];

const useStyles = makeStyles({
  root: {
    width: "90%",
    textTransform: "capitalize",
  },
  container: {
    maxHeight: 440,
  },
});

export const parseName = (patient: PatientDTO) => {
  const name = patient["name"];
  if (name.length > 0) {
    return name[0].text.toLowerCase();
  }
  return "noname";
};

export const parseAthenaID = (patient: PatientDTO) => {
  for (let identifier of patient.identifier) {
    if (identifier.assigner === "Athena") {
      return identifier.value;
    }
  }
  return "noAthenaID";
};

export default function PatientTable(props: PatientTableProps) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.patients
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      if (column.id === "name") {
                        const nombre = parseName(row);
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <Link
                              to={{
                                pathname: "/nightingale/patient/view",
                                state: {
                                  nameStr: nombre,
                                  nameObj: { patient: row },
                                },
                              }}
                            >
                              {nombre}
                            </Link>
                          </TableCell>
                        );
                      }

                      if (column.id === "birthDate") {
                        const DOB = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {format(new Date(DOB), "MMM d, yyyy")}
                          </TableCell>
                        );
                      }

                      if (column.id === "athenaid") {
                        const AthenaID = parseAthenaID(row);
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {AthenaID}
                          </TableCell>
                        );
                      }

                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={props.patients.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
