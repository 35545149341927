import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

export let theme = createMuiTheme({
  spacing: 8,
  palette: {
    primary: {
      light: "#aedff2",
      main: "#166B8C",
      dark: "#00405f",
      contrastText: "#fef4ff",
    },
    secondary: {
      light: "##ca8663",
      main: "#965938",
      dark: "#642f10",
      contrastText: "#fffdf8",
    },
  },
});

theme = responsiveFontSizes(theme);

theme.overrides = {
  MuiGrid: {
    // Name of the component ⚛️ / style sheet
    root: {
      // Name of the rule
      flexGrow: 1,
      padding: theme.spacing(1),
    },
  },
  MuiPaper: {
    rounded: {
      padding: theme.spacing(1),

      backgroundColor: theme.palette.primary.light,
      textAlign: "center",
    },
  },
  MuiTypography: {
    h4: {
      fontFamily: ["B612Mono", "serif"].join(","),
    },
    h5: {
      fontFamily: ["Overlock", "serif"].join(","),
    },
  },
  MuiButton: {
    containedPrimary: {
      "&:hover": {
        // changes colors for hover state

        //backgroundColor: theme.palette.secondary.dark,

        color: theme.palette.primary.light,
      },
    },

    // Name of the component ⚛️ / style sheet
    //text: { // Name of the rule
    //  color: theme.palette.primary.dark,
    //},
  },
};

theme.props = {
  MuiTypography: {},

  MuiButton: {
    // `MuiButton` is the global class name for the <Button /> component
    //disableElevation: false, // this prop disables the drop shadow on all Buttons
  },
};
