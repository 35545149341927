import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import SearchIcon from "@material-ui/icons/Search";
import {
  Paper,
  TextField,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { getToken } from "../../../auth/cognito/access";
import { NCapi } from "../../../api/axios/NCapi";
import PatientTable from "../../patient/components/PatientTable";
import ReloadButton from "../../../auth/components/ReloadButton";
import LoadingSpinner from "../../components/LoadingSpinner";
import { LastMonth } from "../../date/DateString";

interface DiagnosisOption {
  display: string;
  url: string;
}

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function DxSearch() {
  const classes = useStyles();
  let [s, e] = LastMonth();

  const [start, setStart] = useState(s);
  const [end, setEnd] = useState(e);
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(Object());

  const options: DiagnosisOption[] = [
    {
      url: "patient/diabetes",
      display: "Diabetes",
    },
    {
      url: "patient/hypertension",
      display: "Hypertension",
    },
    {
      url: "patient/obesity",
      display: "Obesity",
    },
    {
      url: "patient/renal_disease",
      display: "Renal Disease",
    },
  ];

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleChangeStart = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStart(event.target.value);
  };

  const handleChangeEnd = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnd(event.target.value);
  };

  const DxSearch = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsLoading(true);

    getToken()
      .then((token) => {
        //alert('Token: ' + token)

        NCapi.defaults.headers.common["CognitoJWT"] = token;

        NCapi.post(options[selectedIndex].url, {
          start: start,
          end: end,
        })
          .then((response) => {
            if (response.data.length < 1) {
              alert("The search returned no results.")
            }
            setList(response.data);
            setIsLoading(false);
          })
          .catch((error) => {
            setError(error);
            setIsError(true);
            setIsLoading(false);
          });
      })
      .catch((error) => {
        setError(error);
        setIsError(true);
        setIsLoading(false);
      });

    event.preventDefault();
  };

  if (isError) {
    return (
      <div>
        <Paper>
          <Typography variant="h2">
            Diagnosis Search Error: {error.message}
          </Typography>
          <ReloadButton />
        </Paper>
      </div>
    );
  }

  if (list.length > 0) {
    return (
      <div>
        <PatientTable patients={list} />
      </div>
    );
  }

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Card className={classes.root}>
          <CardContent>
            <CardHeader title="Search for Patients by Diagnosis" />
            <List component="nav" aria-label="Diagnosis settings">
              <ListItem
                button
                aria-haspopup="true"
                aria-controls="lock-menu"
                aria-label="dagnosis to search for"
                onClick={handleClickListItem}
              >
                <ListItemText
                  primary="Click to Select Diagnosis"
                  secondary={options[selectedIndex].display}
                />
              </ListItem>
            </List>
            <Menu
              id="lock-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {options.map((option, index) => (
                <MenuItem
                  key={option.display}
                  selected={index === selectedIndex}
                  onClick={(event) => handleMenuItemClick(event, index)}
                >
                  {option.display}
                </MenuItem>
              ))}
            </Menu>
            <TextField
              name="start"
              value={start}
              onChange={handleChangeStart}
              id="date"
              label="Encounter Date Start"
              type="date"
              defaultValue="2021-09-01"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              name="end"
              value={end}
              onChange={handleChangeEnd}
              id="date"
              label="Encounter Date End"
              type="date"
              defaultValue="2021-10-01"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </CardContent>
          <CardActions>
            <Button
              onClick={DxSearch}
              color="primary"
              variant="contained"
              startIcon={<SearchIcon />}
            >
              Click to Search
            </Button>
          </CardActions>
        </Card>
      )}
    </div>
  );
}
