import React from "react";
import { Button } from "@material-ui/core";
import { Table } from "antd";
import moment from "moment";
import "antd/dist/antd.css";
import { useRecoilState } from "recoil";
import { encListState } from "../../../components/atoms/atoms";
import { CalcBilled } from "./Billed";
import { ParseCPT } from "./CPT";
import { ParsePercentPoverty } from "./PovertyLevel";
import { officialName } from "./Name";
import { CalcModifier, ParseModifier2 } from "./Modifier";
import { FormatTitle } from "./Title";
import { ExportXLS } from "../../../components/excel/ExportXLS";

export default function CentralHealthTable() {
  function parseIDbyAssigner(identifiers: IdentifierDTO[], assigner: string) {
    for (let identifier of identifiers) {
      if (identifier.assigner === assigner) {
        return identifier.value;
      }
    }
    return "";
  }

  function capRender(txt: string) {
    return (
      <div
        style={{
          textTransform: "capitalize",
        }}
      >
        {txt.toLowerCase()}
      </div>
    );
  }

  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (text, record) => (
        <Button
          onClick={(e) => {
            onDelete(record.key, e);
          }}
          color="secondary"
          variant="contained"
        >
          Remove
        </Button>
      ),
    },
    {
      title: "DOS",
      dataIndex: "date",
      key: "date",
      sorter: (a, b) => moment(a.date).diff(moment(b.date)),
    },
    {
      title: "Patient Last Name",
      dataIndex: "lastname",
      key: "lastname",
      sorter: (a, b) => a.lastname.localeCompare(b.lastname),
    },
    {
      title: "Patient First Name",
      dataIndex: "firstname",
      key: "firstname",
      sorter: (a, b) => a.firstname.localeCompare(b.firstname),
    },
    {
      title: "Patient Middle Name",
      dataIndex: "middlename",
      key: "middlename",
      sorter: (a, b) => a.middlename.localeCompare(b.middlename),
    },
    {
      title: "Subscriber Address 1",
      dataIndex: "address1",
      key: "address1",
      sorter: (a, b) => a.address1.localeCompare(b.address1),
      render: capRender,
    },
    {
      title: "Subscriber Address 2",
      dataIndex: "address2",
      key: "address2",
      sorter: (a, b) => a.address2.localeCompare(b.address2),
      render: capRender,
    },
    {
      title: "Subscriber City",
      dataIndex: "city",
      key: "city",
      sorter: (a, b) => a.city.localeCompare(b.city),
      render: capRender,
    },
    {
      title: "Subscriber State",
      dataIndex: "state",
      key: "state",
      sorter: (a, b) => a.state.localeCompare(b.state),
    },
    {
      title: "Subscriber Zip Code",
      dataIndex: "zip",
      key: "zip",
      sorter: (a, b) => a.zip.localeCompare(b.zip),
    },
    {
      title: "DOB",
      dataIndex: "dob",
      key: "dob",
      sorter: (a, b) => a.dob.localeCompare(b.dob),
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      sorter: (a, b) => a.gender.localeCompare(b.gender),
      render: capRender,
    },
    {
      title: "MRN",
      dataIndex: "mrn",
      key: "mrn",
      sorter: (a, b) => a.mrn - b.mrn,
    },
    {
      title: "Group ID",
      dataIndex: "groupid",
      key: "groupid",
      sorter: (a, b) => a.groupid - b.groupid,
    },
    {
      title: "EncID/Claim Id",
      dataIndex: "eid",
      key: "eid",
      sorter: (a, b) => a.eid - b.eid,
    },
    {
      title: "Provider Last Name",
      dataIndex: "pln",
      key: "pln",
      sorter: (a, b) => a.pln.localeCompare(b.pln),
    },
    {
      title: "Provider First Name",
      dataIndex: "fln",
      key: "fln",
      sorter: (a, b) => a.fln.localeCompare(b.fln),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: "NPI",
      dataIndex: "npi",
      key: "npi",
      sorter: (a, b) => a.npi.localeCompare(b.npi),
    },
    {
      title: "Agency",
      dataIndex: "agency",
      key: "agency",
      sorter: (a, b) => a.agency.localeCompare(b.agency),
    },
    {
      title: "Agency NPI",
      dataIndex: "agencynpi",
      key: "agencynpi",
    },
    {
      title: "TIN",
      dataIndex: "tin",
      key: "tin",
    },
    {
      title: "Agency Address 1",
      dataIndex: "agencyaddress1",
      key: "agencyaddress1",
      sorter: (a, b) => a.agencyaddress1.localeCompare(b.agencyaddress1),
      render: capRender,
    },
    {
      title: "Agency Address 2",
      dataIndex: "agencyaddress2",
      key: "agencyaddress2",
      sorter: (a, b) => a.agencyaddress2.localeCompare(b.agencyaddress2),
      render: capRender,
    },
    {
      title: "Agency City",
      dataIndex: "agencycity",
      key: "agencycity",
      sorter: (a, b) => a.agencycity.localeCompare(b.agencycity),
      render: capRender,
    },
    {
      title: "Agency State",
      dataIndex: "agencystate",
      key: "agencystate",
      sorter: (a, b) => a.agencystate.localeCompare(b.agencystate),
    },
    {
      title: "Agency Zip Code",
      dataIndex: "agencyzip",
      key: "agencyzip",
      sorter: (a, b) => a.zip.localeCompare(b.agencyzip),
    },
    {
      title: "Billed $",
      dataIndex: "billed",
      key: "billed",
    },
    {
      title: "ICD",
      dataIndex: "icd",
      key: "icd",
    },
    {
      title: "CPT",
      dataIndex: "cpt",
      key: "cpt",
      //render: cptRender
    },
    {
      title: "Modifier",
      dataIndex: "modifier",
      key: "modifier",
    },
    {
      title: "Modifier2",
      dataIndex: "modifier2",
      key: "modifier2",
      sorter: (a, b) => a.modifier2.localeCompare(b.modifier2),
    },
    {
      title: "Modifier3",
      dataIndex: "modifier3",
      key: "modifier3",
    },
    {
      title: "Modifier4",
      dataIndex: "modifier4",
      key: "modifier4",
    },
    {
      title: "Units",
      dataIndex: "units",
      key: "units",
    },
    {
      title: "Pos",
      dataIndex: "pos",
      key: "pos",
    },
    {
      title: "% Poverty Level",
      dataIndex: "poverty",
      key: "poverty",
      sorter: (a, b) => a.poverty.localeCompare(b.poverty),
      //render: povertyRender
    },
    {
      title: "SSN",
      dataIndex: "ssn",
      key: "ssn",
      sorter: (a, b) => a.ssn - b.ssn,
    },
  ];

  const [encList, setEncList] = useRecoilState(encListState);

  const onDelete = (key, e) => {
    e.preventDefault();
    const encs = encList.filter((item) => item.id !== key);
    setEncList(encs);
  };

  let data: Array<{}> = [];

  encList.forEach((enc: EncounterDTO) => {
    const name = officialName(enc.subject.name);
    const givens = name.given.split(" ");
    let middle = "";
    if (givens.length > 1) {
      middle = givens[1];
    }
    const title = FormatTitle(
      enc.participant[0].individual.qualification[0].code.text
    );

    const mrn = parseIDbyAssigner(enc.subject.identifier, "Athena");
    const ssn = parseIDbyAssigner(
      enc.subject.identifier,
      "http://hl7.org/fhir/sid/us-ssn"
    );

    let icd = "";

    if (enc.diagnosis.length > 0) {
      icd = enc.diagnosis[0].condition.code.coding[0].code;
    }

    data.push({
      key: enc.id,
      date: enc.period.start,
      lastname: name.family,
      firstname: givens[0],
      middlename: middle,
      address1: enc.subject.address[0].line,
      address2: "",
      city: enc.subject.address[0].city,
      state: enc.subject.address[0].state,
      zip: enc.subject.address[0].postalCode,
      dob: enc.subject.birthDate,
      gender: enc.subject.gender,
      mrn: mrn,
      groupid: "53273000",
      eid: parseIDbyAssigner(enc.identifier, "Athena"),
      pln: enc.participant[0].individual.name[0].family,
      fln: enc.participant[0].individual.name[0].given,
      title: title,
      npi: parseIDbyAssigner(enc.participant[0].individual.identifier, "NPPES"),
      agency: "Volunteer Health Clinic",
      agencynpi: "",
      tin: "74-6082464",
      agencyaddress1: "4215 Medical Parkway",
      agencyaddress2: "",
      agencycity: "Austin",
      agencystate: "TX",
      agencyzip: "78756",
      billed: CalcBilled(title),
      icd: icd,
      //cpt: enc,
      cpt: ParseCPT(enc.type),
      modifier: CalcModifier(title),
      //modifier2: CalcModifier2(enc.type[0].text),
      modifier2: ParseModifier2(enc.type),
      modifier3: "",
      modifier4: "",
      units: "1",
      pos: "11",
      //poverty: enc.subject.id
      poverty: ParsePercentPoverty(enc.subject.observation),
      ssn: ssn,
    });
  });

  return (
    <>
      <ExportXLS
        Data={data}
        Columns={columns.slice(1).map((c) => c.title)}
        Keys={columns.slice(1).map((c) => c.key)}
        fileName={"CH"}
      />
      <Table
        columns={columns}
        dataSource={data}
        pagination={{ defaultPageSize: 5 }}
      />
    </>
  );
}
