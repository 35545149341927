import React, { Component } from "react";
import { Grid, Typography } from "@material-ui/core";
import { PatientRoutes } from "./components/routes/PatientRoutes";
import { PopulationRoutes } from "./components/routes/PopulationRoutes";
import NightingaleBanner from "./components/NightingaleBanner";

interface NightingaleProps {}

interface NightingaleState {
  error: Error;
  isError: boolean;
  isLoading: boolean;
}

class Nightingale extends Component<NightingaleProps, NightingaleState> {
  state: Readonly<NightingaleState> = {
    error: Object(),
    isError: false,
    isLoading: false,
  };

  render() {
    if (this.state.isError) {
      return (
        <div>
          <Typography variant="h3">Nightingale Error</Typography>
        </div>
      );
    }

    return (
      <div>
        <Typography align="center" variant="h5">
          Nightingale Home
        </Typography>
        <p></p>
        <NightingaleBanner />
        <Grid
          container
          spacing={1}
          alignContent="space-around"
          justify="flex-start"
        >
          <Grid item xs>
            <PatientRoutes
              match={{
                path: "/nightingale/patient/",
                url: "/nightingale/patient/",
              }}
            />
            <PopulationRoutes
              match={{
                path: "/population",
                url: "/population",
              }}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Nightingale;
