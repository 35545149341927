import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import SearchIcon from "@material-ui/icons/Search";
import { Paper, TextField, Button, Typography } from "@material-ui/core";
import { getToken } from "../../../../auth/cognito/access";
import { NCapi } from "../../../../api/axios/NCapi";
import ReloadButton from "../../../../auth/components/ReloadButton";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { YearThroughLastMonth } from "../../../date/DateString";
import CentralHealthTable from "./CentralHealthTable";
import { useSetRecoilState } from "recoil";
import { encListState } from "../../../components/atoms/atoms";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function CentralHealth() {
  const classes = useStyles();
  const [s, e] = YearThroughLastMonth();
  const [start, setStart] = React.useState(s);
  const [end, setEnd] = React.useState(e);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [list, setList] = React.useState([]);
  const [error, setError] = React.useState(Object());
  const setEncList = useSetRecoilState(encListState);

  const handleChangeStart = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStart(event.target.value);
  };

  const handleChangeEnd = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnd(event.target.value);
  };

  const IcdSearch = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setIsLoading(true);

    getToken()
      .then((token) => {
        NCapi.defaults.headers.common["CognitoJWT"] = token;

        NCapi.post("encounter/ch/athena", {
          start: start,
          end: end,
        })
          .then((response) => {
            if (response.data.length < 1) {
              alert("The search returned no results.")
            }
            setList(response.data);
            setIsLoading(false);
          })
          .catch((error) => {
            setError(error);
            setIsError(true);
            setIsLoading(false);
          });
      })
      .catch((error) => {
        setError(error);
        setIsError(true);
        setIsLoading(false);
      });

    event.preventDefault();
  };

  if (isError) {
    return (
      <div>
        <Paper>
          <Typography variant="h2">Central Health Search Error: {error}</Typography>
          <ReloadButton />
        </Paper>
      </div>
    );
  }

  if (list.length > 0) {
    setEncList(list);
    return (
      <div>
        <CentralHealthTable encounters={list} />
      </div>
    );
  }

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Card className={classes.root}>
          <CardContent>
            <CardHeader title="Generate Central Health Encounter Report" />
            <p></p>
            <TextField
              name="start"
              value={start}
              onChange={handleChangeStart}
              id="date"
              label="Encounter Date Start"
              type="date"
              defaultValue="2020-11-01"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              name="end"
              value={end}
              onChange={handleChangeEnd}
              id="date"
              label="Encounter Date End"
              type="date"
              defaultValue="2023-01-01"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </CardContent>
          <CardActions>
            <Button
              onClick={IcdSearch}
              color="primary"
              variant="contained"
              startIcon={<SearchIcon />}
            >
              Click to Preview Report
            </Button>
          </CardActions>
        </Card>
      )}
    </div>
  );
}
