import React, { Component } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Paper,
  ButtonGroup,
  Button,
  Typography,
  Grid,
} from "@material-ui/core";
import { AxiosError } from "axios";
import { PatientDTO } from "../dto/patient";
import PatientBanner from "../patient/components/PatientBanner";
import ReloadButton from "../../auth/components/ReloadButton";
import LabChart from "./components/LabChart";
import LoadingSpinner from "../components/LoadingSpinner";
import { getToken } from "../../auth/cognito/access";
import { NCapi } from "../../api/axios/NCapi";

interface LaboratoryProps {
  location: {
    state: {
      nameStr: string;
      ptObj: PatientDTO;
    };
  };
}

interface LaboratoryState {
  error: AxiosError;
  isLoading: boolean;
  isError: boolean;
  charts: string[];
}

const testsAvailable: string[] = ["glucose", "hba1c"];

class Laboratory extends Component<LaboratoryProps, LaboratoryState> {
  state: Readonly<LaboratoryState> = {
    error: Object(),
    isLoading: false,
    isError: false,
    charts: [],
  };

  componentDidMount() {
    this.setState({ isLoading: true });

    const encodedID = encodeURIComponent(
      this.props.location.state.ptObj.id.toString()
    );

    getToken()
      .then((token) => {
        //alert('Token: ' + token)
        NCapi.defaults.headers.common["CognitoJWT"] = token;

        testsAvailable.forEach((c: string) => {
          NCapi.get(`observation/${c}/?patientID=${encodedID}`)
            .then((result) => {
              if (result.data.length > 0) {
                this.setState({
                  charts: [...this.state.charts, c],
                });
              }
            })
            .catch((error) =>
              this.setState({
                error,
                isError: true,
                isLoading: false,
              })
            );
        });

        this.setState({
          isLoading: false,
        });
      })
      .catch((error) =>
        this.setState({
          error,
          isError: true,
          isLoading: false,
        })
      );
  }

  render() {
    if (this.state.isError) {
      return (
        <div>
          <Paper>
            <Typography variant="h2">Laboratory Error</Typography>
            <ReloadButton />
          </Paper>
        </div>
      );
    }

    return (
      <div>
        {this.state.isLoading ? (
          <LoadingSpinner />
        ) : (
          <div>
            <Paper>
              <PatientBanner
                title="Laboratory View for"
                nameStr={this.props.location.state.nameStr}
                patient={this.props.location.state.ptObj}
              />
              <Grid
                container
                spacing={1}
                alignContent="space-around"
                justify="flex-start"
              >
                {this.state.charts.map((c) => (
                  <Grid item xs>
                    <Button
                      color="primary"
                      component={RouterLink}
                      to={{
                        pathname: `/nightingale/patient/${c}`,
                        state: {
                          nameStr: this.props.location.state.nameStr,
                          ptObj: this.props.location.state.ptObj,
                        },
                      }}
                    >
                      <LabChart
                        test={c}
                        pid={this.props.location.state.ptObj.id}
                      />
                    </Button>
                  </Grid>
                ))}
              </Grid>
              <ButtonGroup
                variant="text"
                aria-label="text primary button group"
              >
                <Button
                  color="primary"
                  component={RouterLink}
                  to={{
                    pathname: "/nightingale/patient/glucose",
                    state: {
                      nameStr: this.props.location.state.nameStr,
                      ptObj: this.props.location.state.ptObj,
                    },
                  }}
                >
                  Blood Glucose
                </Button>
                <Button
                  color="primary"
                  component={RouterLink}
                  to={{
                    pathname: "/nightingale/patient/hba1c",
                    state: {
                      nameStr: this.props.location.state.nameStr,
                      ptObj: this.props.location.state.ptObj,
                    },
                  }}
                >
                  Hgb A1C
                </Button>
              </ButtonGroup>
            </Paper>
          </div>
        )}
      </div>
    );
  }
}

export default Laboratory;
