export const CalcBilled = (title: string) => {
  let mdRE = /^M\.?D\.?$/;
  let doRE = /^D\.?O\.?$/;
  let paRE = /^P.*A.*$/;
  let npRE = /^N.*P.*$/;
  let cnsRE = /^C.*N.*S.*$/;

  if (mdRE.test(title) || doRE.test(title)) {
    return "$133";
  } else if (paRE.test(title) || npRE.test(title) || cnsRE.test(title)) {
    return "$67";
  }

  return "$0";
};
