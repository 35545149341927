import React, { Component } from "react";
import Moment from "react-moment";
import { Grid, Paper, Typography } from "@material-ui/core";
import { PatientDTO } from "../../dto/patient";

interface PatientBannerProps {
  title: string;
  nameStr: string;
  patient: PatientDTO;
}

interface PatientBannerState {}

class PatientBanner extends Component<PatientBannerProps, PatientBannerState> {
  state: Readonly<PatientBannerState> = {};

  render() {
    let AID = "unknown";
    for (let identifier of this.props.patient.identifier) {
      if (identifier.assigner === "Athena") {
        AID = identifier.value;
      }
    }
    return (
      <Grid
        container
        spacing={1}
        alignContent="space-around"
        justify="flex-start"
      >
        <Grid item xs>
          <Paper>
            <Typography variant="h5">{this.props.title}</Typography>
            <Typography variant="h5">for</Typography>
            <Typography
              variant="h5"
              style={{
                textTransform: "capitalize",
              }}
            >
              {this.props.nameStr}
            </Typography>
            <Typography variant="h5">{this.props.patient.gender}</Typography>
            <Typography variant="h5">
              DOB:&nbsp;
              <Moment format="MMMM Do YYYY">
                {this.props.patient.birthDate}
              </Moment>
            </Typography>
            <Typography variant="h5">
              AthenaID:&nbsp;
              {AID}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default PatientBanner;
