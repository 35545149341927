/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_cognito_identity_pool_id": "ca-central-1:90dbe986-a8ef-463d-bdac-28962663dffc",
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_zkFp1sR2y",
    "aws_user_pools_web_client_id": "1kb27n9sh7h36h3d8iee91ptcp",
    "oauth": {}
};


export default awsmobile;
