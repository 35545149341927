import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const LoadingSpinner = () => (
  <div>
    <CircularProgress size={20} />
    Loading...
  </div>
);

export default LoadingSpinner;
