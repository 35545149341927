import React, { Component } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Grid, Paper, Typography, Button, Link } from "@material-ui/core";
import { EncounterDTO } from "../dto/encounter";
import { PatientDTO } from "../dto/patient";
import LoadingSpinner from "../components/LoadingSpinner";
import { AxiosError } from "axios";
import HealingIcon from "@material-ui/icons/Healing";
import CodeIcon from "@material-ui/icons/Code";
import CenterFocusWeakIcon from "@material-ui/icons/CenterFocusWeak";

interface PopulationDashboardProps {}

interface PopulationDashboardState {
  encounterList: EncounterDTO[];
  ptObj: PatientDTO;
  error: AxiosError;
  isError: boolean;
  isLoading: boolean;
}

class PopulationDashboard extends Component<
  PopulationDashboardProps,
  PopulationDashboardState
> {
  state: Readonly<PopulationDashboardState> = {
    encounterList: [],
    ptObj: Object(),
    error: Object(),
    isError: false,
    isLoading: false,
  };

  render() {
    if (this.state.isError) {
      return (
        <div>
          <Typography variant="h3">Population Analysis Error</Typography>
        </div>
      );
    }

    return (
      <div>
        {this.state.isLoading ? (
          <LoadingSpinner />
        ) : (
          <div>
            <Grid
              container
              spacing={1}
              alignContent="space-around"
              justify="flex-start"
            >
              <Grid item xs>
                <Paper>
                  <Typography variant="h4">Population Analysis Home</Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              alignContent="space-around"
              justify="flex-start"
            >
              <Grid item xs>
                <Paper>
                  <Link
                    component={RouterLink}
                    to={{
                      pathname: "/population/dx_search",
                    }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      startIcon={<HealingIcon />}
                    >
                      Diagnosis Search
                    </Button>
                  </Link>
                </Paper>
              </Grid>
              <Grid item xs>
                <Paper>
                  <Link
                    component={RouterLink}
                    to={{
                      pathname: "/population/icd_search",
                    }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      startIcon={<CodeIcon />}
                    >
                      ICD10 Search
                    </Button>
                  </Link>
                </Paper>
              </Grid>
              <Grid item xs>
                <Paper>
                  <Link
                    component={RouterLink}
                    to={{
                      pathname: "/population/central_health",
                    }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      startIcon={<CenterFocusWeakIcon />}
                    >
                      Central Health
                    </Button>
                  </Link>
                </Paper>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    );
  }
}

export default PopulationDashboard;
