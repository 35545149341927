import React from "react";
import { Button } from "@material-ui/core";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import CenterFocusStrongIcon from "@material-ui/icons/CenterFocusStrong";

export const ExportXLS = ({ Data, Columns, Keys, fileName }) => {
  let xlsData: Array<{}> = [];
  xlsData.push(Columns);

  for (let row of Data) {
    let xlsRow: Array<{}> = [];
    for (let key of Keys) {
      xlsRow.push(row[key]);
    }
    xlsData.push(xlsRow);
  }

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xls";

  const exportToXLS = (xlsData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(xlsData, { skipHeader: true });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "biff8", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button
      onClick={(e) => exportToXLS(xlsData, fileName)}
      color="primary"
      variant="contained"
      startIcon={<CenterFocusStrongIcon />}
    >
      Export
    </Button>
  );
};
