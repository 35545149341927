import { Auth } from "aws-amplify";
import React, { Component } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Paper, ButtonGroup, Button } from "@material-ui/core";
import { HumanNameDTO } from "../dto/humanname";
import { PatientDTO } from "../dto/patient";
import PatientBanner from "./components/PatientBanner";

interface ClinicalDataProps {
  location: {
    state: {
      nameStr: string;
      nameObj: HumanNameDTO;
      ptObj: PatientDTO;
    };
  };
}

interface ClinicalDataState {
  cognitoToken: string;
  error: Error;
  isError: boolean;
  isLoading: boolean;
}

class ClinicalData extends Component<ClinicalDataProps, ClinicalDataState> {
  state: Readonly<ClinicalDataState> = {
    cognitoToken: "",
    error: Object(),
    isError: false,
    isLoading: false,
  };

  componentDidMount() {
    this.setState({ isLoading: true });

    Auth.currentSession()
      .then((result) => {
        this.setState({
          cognitoToken: result.getAccessToken().getJwtToken(),
          isLoading: false,
        });
      })
      .catch((error) =>
        this.setState({
          error,
          isLoading: false,
          isError: true,
        })
      );
  }

  render() {
    if (this.state.isError) {
      return (
        <div>
          <p>Clinical Data Error</p>
        </div>
      );
    }

    return (
      <div>
        <Paper>
          <PatientBanner
            title="Clinical Data for"
            nameStr={this.props.location.state.nameStr}
            patient={this.props.location.state.ptObj}
          />
          <ButtonGroup variant="text" aria-label="text primary button group">
            <Button
              color="primary"
              component={RouterLink}
              to={{
                pathname: "/nightingale/patient/laboratory",
                state: {
                  cognitoToken: this.state.cognitoToken,
                  nameStr: this.props.location.state.nameStr,
                  ptObj: this.props.location.state.ptObj,
                },
              }}
            >
              Laboratory
            </Button>
            <Button>Vital Signs</Button>
            <Button>Imaging</Button>
          </ButtonGroup>
        </Paper>
      </div>
    );
  }
}

export default ClinicalData;
