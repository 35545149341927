import { Auth } from "aws-amplify";
import React, { Component } from "react";
import { HumanNameDTO } from "../dto/humanname";
import { Link } from "react-router-dom";

interface HumanNameRowProps {
  humanname: HumanNameDTO;
}

interface HumanNameRowState {
  cognitoToken: string;
  error: Error;
  isError: boolean;
  isLoading: boolean;
}

class HumanNameRow extends Component<HumanNameRowProps, HumanNameRowState> {
  state: Readonly<HumanNameRowState> = {
    cognitoToken: "",
    error: Object(),
    isError: false,
    isLoading: false,
  };

  componentDidMount() {
    this.setState({ isLoading: true });

    Auth.currentSession()
      .then((result) => {
        this.setState({
          cognitoToken: result.getAccessToken().getJwtToken(),
          isLoading: false,
        });
      })
      .catch((error) =>
        this.setState({
          error,
          isLoading: false,
          isError: true,
        })
      );
  }

  render() {
    if (this.state.isError) {
      return (
        <div>
          <p>Human Name Row Error</p>
        </div>
      );
    }

    const humanname = this.props.humanname;
    const nameString = humanname.given + " " + humanname.family;

    return (
      <tr>
        <td
          style={{
            textTransform: "capitalize",
          }}
        >
          <Link
            to={{
              pathname: "/nightingale/patient/view",
              state: {
                nameStr: nameString.toLowerCase(),
                nameObj: humanname,
              },
            }}
          >
            {nameString.toLowerCase()}
          </Link>
        </td>
      </tr>
    );
  }
}

export default HumanNameRow;
