import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import PatientBanner from "./components/PatientBanner";
import { PatientDTO } from "../dto/patient";

interface MedicalStatusProps {
  location: {
    state: {
      nameStr: string;
      ptObj: PatientDTO;
    };
  };
}

function MedicalStatus(props: MedicalStatusProps) {
  return (
    <div>
      <Paper elevation={10}>
        <PatientBanner
          title="Medical Status for"
          nameStr={props.location.state.nameStr}
          patient={props.location.state.ptObj}
        />
        <Typography variant="h5" component="h3">
          This is a sheet of paper.
        </Typography>
        <Typography component="p">
          Paper can be used to build surface or other elements for your
          application.
        </Typography>
      </Paper>
    </div>
  );
}

export default MedicalStatus;
