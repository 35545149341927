import React from "react";
import { AmplifySignOut } from "@aws-amplify/ui-react";
import { Switch, Route } from "react-router-dom";
import Routes, { IRoute } from "./components/routes/Routes";
import NavigationBar from "./pages/components/NavigationBar";
import { Helmet } from "react-helmet";
import ErrorBoundary from "./error/ErrorBoundary";
import { RecoilRoot } from "recoil";

const App: React.FC = () => {
  return (
    <div>
      <Helmet>
        <title>{process.env.REACT_APP_ORG_NAME + " HIE"}</title>
      </Helmet>
      <RecoilRoot>
        <ErrorBoundary>
          <NavigationBar />
          <AmplifySignOut />
          <Switch>
            {Routes.map((route: IRoute) => (
              <Route path={route.path} key={route.path}>
                <route.component />
              </Route>
            ))}
          </Switch>
        </ErrorBoundary>
      </RecoilRoot>
    </div>
  );
};

export default App;
