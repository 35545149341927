import React, { Component } from "react";
import { Link as RouterLink } from "react-router-dom";
import { AxiosError } from "axios";
import {
  Grid,
  Paper,
  ButtonGroup,
  Button,
  Typography,
} from "@material-ui/core";
import { HumanNameDTO } from "../dto/humanname";
import { PatientDTO } from "../dto/patient";
import PatientBanner from "./components/PatientBanner";
import LoadingSpinner from "../components/LoadingSpinner";
import ReloadButton from "../../auth/components/ReloadButton";
import { getToken } from "../../auth/cognito/access";
import { NCapi } from "../../api/axios/NCapi";

interface PatientViewProps {
  location: {
    state: {
      nameStr: string;
      nameObj: HumanNameDTO;
    };
  };
}

interface PatientViewState {
  ptObj: PatientDTO;
  error: AxiosError;
  isLoading: boolean;
  isError: boolean;
}

class PatientView extends Component<PatientViewProps, PatientViewState> {
  state: Readonly<PatientViewState> = {
    ptObj: Object(),
    error: Object(),
    isLoading: true,
    isError: false,
  };

  componentDidMount() {
    const encodedID = encodeURIComponent(
      this.props.location.state.nameObj.patient.id.toString()
    );

    this.setState({ isLoading: true });

    getToken()
      .then((token) => {
        //alert('Token: ' + token)
        NCapi.defaults.headers.common["CognitoJWT"] = token;

        NCapi.get(`patient/${encodedID}`)
          .then((result) =>
            this.setState({
              ptObj: result.data,
              isLoading: false,
            })
          )
          .catch((error) =>
            this.setState({
              error,
              isError: true,
              isLoading: false,
            })
          );
      })
      .catch((error) =>
        this.setState({
          error,
          isError: true,
          isLoading: false,
        })
      );
  }

  render() {
    if (this.state.isError) {
      return (
        <div>
          <Paper>
            <Typography variant="h2">Patient View Error</Typography>
            <ReloadButton />
          </Paper>
        </div>
      );
    }

    return (
      <div>
        {this.state.isLoading ? (
          <LoadingSpinner />
        ) : (
          <div>
            <PatientBanner
              title="Patient View"
              nameStr={this.props.location.state.nameStr}
              patient={this.state.ptObj}
            />
            <Grid
              container
              spacing={1}
              alignContent="space-around"
              justify="flex-start"
            >
              <Grid item xs>
                <Paper>
                  <ButtonGroup
                    variant="text"
                    aria-label="text primary button group"
                  >
                    <Button
                      component={RouterLink}
                      to={{
                        pathname: "/nightingale/patient/background",
                        state: {
                          nameStr: this.props.location.state.nameStr,
                          ptObj: this.state.ptObj,
                        },
                      }}
                    >
                      Background
                    </Button>
                    <Button
                      component={RouterLink}
                      to={{
                        pathname: "/nightingale/patient/medicalstatus",
                        state: {
                          nameStr: this.props.location.state.nameStr,
                          ptObj: this.state.ptObj,
                        },
                      }}
                    >
                      Medical Status
                    </Button>
                    <Button
                      component={RouterLink}
                      to={{
                        pathname: "/nightingale/patient/clinicaldata",
                        state: {
                          nameStr: this.props.location.state.nameStr,
                          ptObj: this.state.ptObj,
                        },
                      }}
                    >
                      Clinical Data
                    </Button>
                    <Button>History</Button>
                  </ButtonGroup>
                </Paper>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    );
  }
}

export default PatientView;
