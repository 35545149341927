import React from "react";
import {
  VictoryVoronoiContainer,
  VictoryChart,
  VictoryAxis,
  VictoryTheme,
  VictoryGroup,
  VictoryArea,
  VictoryTooltip,
} from "victory";
import { Paper, Typography } from "@material-ui/core";
import ReloadButton from "../../../auth/components/ReloadButton";
import moment from "moment";
import { ObservationDTO } from "../../dto/observation";
import { getToken } from "../../../auth/cognito/access";
import { NCapi } from "../../../api/axios/NCapi";
import LoadingSpinner from "../../components/LoadingSpinner";

interface LabChartProps {
  test: string;
  pid: number;
}

const defaultObservations: ObservationDTO[] = [];

const LabChart = (props: LabChartProps) => {
  const [observations, setObservations]: [
    ObservationDTO[],
    (observations: ObservationDTO[]) => void
  ] = React.useState(defaultObservations);

  const [loading, setLoading]: [boolean, (loading: boolean) => void] =
    React.useState<boolean>(true);

  const [error, setError]: [string, (error: string) => void] =
    React.useState("");

  React.useEffect(() => {
    const encodedID = encodeURIComponent(props.pid.toString());

    getToken().then((token) => {
      //alert('Token: ' + token)
      NCapi.defaults.headers.common["CognitoJWT"] = token;

      NCapi.get<ObservationDTO[]>(
        `observation/${props.test}/?patientID=${encodedID}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          setObservations(response.data);
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    });
  });

  if (error) {
    return (
      <div>
        <Paper>
          <Typography variant="h2">Laboratory Error</Typography>
          <ReloadButton />
        </Paper>
      </div>
    );
  }

  let data: Array<{}> = [];
  let dateTicks: Array<String> = [];
  observations.forEach((obs: ObservationDTO) => {
    dateTicks.push(moment(obs.effectiveDateTime).format("MM/YY"));
    data.push({
      date: moment(obs.effectiveDateTime).format("MM/YY"),
      value: obs.valueQuantity.value,
      unit: obs.valueQuantity.unit,
    });
  });

  let title: string = "No Test";

  if (data.length > 0) {
    title = observations[0].code.coding[0].display;
  }

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <Typography variant="subtitle1">{title}</Typography>
          <VictoryChart
            width={500}
            height={200}
            domainPadding={10}
            theme={VictoryTheme.material}
            containerComponent={
              <VictoryVoronoiContainer
                labels={({ datum }) =>
                  `${moment(datum.date, "MM/YY").format("MMM/YY")}, ${
                    datum.value
                  } ${datum.unit}  `
                }
                labelComponent={<VictoryTooltip style={{ fontSize: 5 }} />}
              />
            }
          >
            <VictoryAxis
              tickValues={dateTicks}
              style={{
                tickLabels: { fontSize: 5 },
              }}
            />
            <VictoryAxis
              dependentAxis
              tickFormat={(x) => `${x}`}
              style={{
                tickLabels: { fontSize: 5 },
              }}
            />
            <VictoryGroup
              style={{
                data: { strokeWidth: 1, fillOpacity: 0.4 },
              }}
            >
              <VictoryArea
                style={{
                  data: { fill: "#f44336", stroke: "#880e4f" },
                }}
                data={data}
                x={"date"}
                y={"value"}
              />
            </VictoryGroup>
          </VictoryChart>
        </div>
      )}
    </div>
  );
};

export default LabChart;
