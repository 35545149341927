import React, { Component } from "react";
import { Paper } from "@material-ui/core";
import { PatientDTO } from "../../../dto/patient";
import LabChart from "../../components/LabChart";
import PatientBanner from "../../../patient/components/PatientBanner";

interface BGChartProps {
  location: {
    state: {
      nameStr: string;
      ptObj: PatientDTO;
    };
  };
}

interface BGChartState {}

class BGChart extends Component<BGChartProps, BGChartState> {
  state: Readonly<BGChartState> = {};

  render() {
    return (
      <div>
        <Paper>
          <PatientBanner
            title="Lab Detail for"
            nameStr={this.props.location.state.nameStr}
            patient={this.props.location.state.ptObj}
          />
          <LabChart test="glucose" pid={this.props.location.state.ptObj.id} />
        </Paper>
      </div>
    );
  }
}

export default BGChart;
