import { BrowserRouter as Router } from "react-router-dom";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "normalize.css";
import { ThemeProvider } from "@material-ui/core";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
import { AmplifyAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";
import "typeface-overlock";
import "typeface-b612-mono";

import { theme } from "./style/default";
import "./index.css";

Amplify.configure(awsconfig);
const signInTitle = process.env.REACT_APP_ORG_NAME + " HIE";

ReactDOM.render(
  <React.StrictMode>
    <AmplifyAuthenticator>
      <AmplifySignIn
        headerText={signInTitle}
        slot="sign-in"
        hideSignUp
      ></AmplifySignIn>
      <ThemeProvider theme={theme}>
        <Router>
          <App />
        </Router>
      </ThemeProvider>
    </AmplifyAuthenticator>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
