import React, { Component } from "react";
import HumanNameRow from "./HumanNameRow";
import { HumanNameDTO } from "../dto/humanname";
import { Grid } from "@material-ui/core";

interface HumanNameTableProps {
  humannames: HumanNameDTO[];
}

interface HumanNameTableState {}

class HumanNameTable extends Component<
  HumanNameTableProps,
  HumanNameTableState
> {
  render() {
    let rows: Array<JSX.Element> = [];

    this.props.humannames.forEach((humanname) => {
      rows.push(<HumanNameRow humanname={humanname} key={humanname.id} />);
    });

    return (
      <div>
        <Grid
          container
          spacing={1}
          alignContent="space-around"
          justify="flex-start"
        >
          <Grid item xs>
            <table>
              <thead>
                <tr>
                  <th>Name Search Results</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </table>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default HumanNameTable;
