import React, { useState, useEffect } from "react";
import { getToken } from "../../../../auth/cognito/access";
import { NCapi } from "../../../../api/axios/NCapi";

export function PovertyLevel(props) {
  const [persons, setPersons] = useState(1);
  const [income, setIncome] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const fpl_array = [
    12880.0, // family of 1
    17420.0, // family of 2
    21960.0, // family of 3
    26500.0, // family of 4
    31040.0, // etc.
    35580.0,
    40120.0,
    44660.0,
    49200.0,
    53740.0, // family of 10
    58280.0,
    62820.0,
    67360.0,
    71900.0,
    76440.0,
    80980.0,
    85520.0,
    90060.0,
    94600.0,
    99140.0, // family of 20
    103680.0,
    108220.0,
    112760.0,
    117300.0,
    121840.0,
  ];
  const [fpl_val, setFpl_val] = useState(fpl_array[persons - 1]);

  useEffect(() => {
    const encodedID = encodeURIComponent(props.pid.toString());

    getToken().then((token) => {
      NCapi.defaults.headers.common["CognitoJWT"] = token;

      NCapi.get<number>(`patient/household/${encodedID}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          setPersons(response.data);
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });

      NCapi.get<number>(`patient/income/${encodedID}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          setIncome(response.data);
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    });
  });

  if (persons > 10) {
    setFpl_val(fpl_array[9] + (persons - 10) * fpl_addperson);
  }

  if (error) {
    return <div>Poverty Level Error</div>;
  }

  if (loading) {
    return "Loading...";
  }

  return (
    <div>
      {Math.ceil((((income * 12) / fpl_val) * 100).toFixed(2) / 50) * 50}%
    </div>
  );
}

export const ParsePercentPoverty = (obsList: ObservationDTO[]) => {
  let percent = 0;

  obsList.forEach((obs: ObservationDTO) => {
    if (!(typeof obs.code === "undefined")) {
      if (!(typeof obs.code.coding === "undefined")) {
        obs.code.coding.forEach((coding: CodingDTO) => {
          if (coding.code === "74185-0") {
            percent = obs.valueString;
          }
        });
      }
    }
  });

  return percent;
};
