import { Link as RouterLink } from "react-router-dom";
import React from "react";
import { Button, Grid, Link, Paper } from "@material-ui/core";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import PeopleIcon from "@material-ui/icons/People";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(1),
      //padding: '20px'
    },
  })
);

export default function NightingaleBanner() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={1}
        alignContent="space-around"
        justify="flex-start"
      >
        <Grid item xs>
          <Paper>
            <Link
              component={RouterLink}
              to={{
                pathname: "/nightingale/patient/search",
              }}
            >
              <Button
                color="primary"
                variant="contained"
                startIcon={<AssignmentIndIcon />}
              >
                Patient Search
              </Button>
            </Link>
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper>
            <Link
              component={RouterLink}
              to={{
                pathname: "/population/dashboard",
              }}
            >
              <Button
                color="primary"
                variant="contained"
                startIcon={<PeopleIcon />}
              >
                Population Analysis
              </Button>
            </Link>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
