import { atom } from "recoil";

export const encListState = atom({
  key: "encListState",
  default: [],
});

export const cptListState = atom({
  key: "cptList",
  default: [],
});
