import React, { Component } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Paper, ButtonGroup, Button } from "@material-ui/core";
import { PatientDTO } from "../dto/patient";
import PatientBanner from "./components/PatientBanner";

interface BackgroundProps {
  location: {
    state: {
      nameStr: string;
      ptObj: PatientDTO;
    };
  };
}

interface BackgroundState {}

class Background extends Component<BackgroundProps, BackgroundState> {
  state: Readonly<BackgroundState> = {};

  render() {
    return (
      <div>
        <Paper>
          <PatientBanner
            title="Background View for"
            nameStr={this.props.location.state.nameStr}
            patient={this.props.location.state.ptObj}
          />
          <ButtonGroup variant="text" aria-label="text primary button group">
            <Button
              color="primary"
              component={RouterLink}
              to={{
                pathname: "/background",
                state: {
                  nameStr: this.props.location.state.nameStr,
                  ptObj: this.props.location.state.ptObj,
                },
              }}
            >
              Demographics
            </Button>
            <Button>Risk Factors</Button>
            <Button href="/">Social Determinants of Health</Button>
            <Button>Family History</Button>
          </ButtonGroup>
        </Paper>
      </div>
    );
  }
}

export default Background;
