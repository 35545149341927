import React, { Component } from "react";
import { PopulationRoutes } from "../components/routes/PopulationRoutes";
import { AxiosError } from "axios";
import { Grid } from "@material-ui/core";

interface PopulationViewProps {}

interface PopulationViewState {
  error: AxiosError;
  isLoading: boolean;
  isError: boolean;
}

class PopulationView extends Component<
  PopulationViewProps,
  PopulationViewState
> {
  state: Readonly<PopulationViewState> = {
    error: Object(),
    isLoading: false,
    isError: false,
  };

  componentDidMount() {}

  render() {
    return (
      <div>
        <Grid
          container
          spacing={1}
          alignContent="space-around"
          justify="flex-start"
        >
          <Grid item xs>
            <PopulationRoutes
              match={{
                path: "/population/",
                url: "/population/",
              }}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default PopulationView;
