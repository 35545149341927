import {
  format,
  lastDayOfMonth,
  startOfMonth,
  subMonths,
  subYears,
} from "date-fns";

export const LastMonth = () => {
  const monthAgo = subMonths(new Date(), 1);

  const start = startOfMonth(monthAgo),
    end = lastDayOfMonth(monthAgo);

  var startString = format(start, "yyyy-MM-dd");
  var endString = format(end, "yyyy-MM-dd");

  return [startString, endString];
};

export const YearThroughLastMonth = () => {
  const monthAgo = subMonths(new Date(), 1);
  const yearAgo = subYears(new Date(), 1);

  const start = startOfMonth(yearAgo),
    end = lastDayOfMonth(monthAgo);

  var startString = format(start, "yyyy-MM-dd");
  var endString = format(end, "yyyy-MM-dd");

  return [startString, endString];
};
