import { Auth } from "aws-amplify";
import axios from "axios";

export function setToken() {
  getToken()
    .then((token) => {
      //alert('Token: ' + token)
      axios.interceptors.request.use(
        (request) => {
          request.headers["CognitoJWT"] = token;
          return request;
        },
        (error) => {
          alert("Failed to set interceptor");
          return Promise.reject(error);
        }
      );
    })
    .catch((error) => {
      alert(error);
    });
}

export async function getToken() {
  return new Promise<string>((res) => {
    Auth.currentSession()
      .then((result) => {
        res(result.getAccessToken().getJwtToken());
      })
      .catch((error) => {
        res(error);
      });
  });
}

export function waitToken() {
  return new Promise<string>((res) => {
    Auth.currentSession()
      .then((result) => {
        res(result.getAccessToken().getJwtToken());
      })
      .catch((error) => {
        res(error);
      });
  });
}
