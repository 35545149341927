import React, { useState } from "react";
import { NavLink, withRouter, RouteComponentProps } from "react-router-dom";
import Routes from "../../components/routes/Routes";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  MenuList,
  MenuItem,
  ListItemText,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    drawer: {
      width: 300,
    },
    fullList: {
      width: "auto",
    },
  })
);

const NavigationBar: React.FC<RouteComponentProps> = ({
  history,
  location,
  match,
}: RouteComponentProps) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setIsOpen(open);
    };

  const activeRoute = (routeName: any) => {
    return location.pathname === routeName ? true : false;
  };

  const reversedRoutes = Routes.map((item) => item).reverse();

  return (
    <div>
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {process.env.REACT_APP_ORG_NAME} HIE
            </Typography>
          </Toolbar>
        </AppBar>
      </div>
      <Drawer
        classes={{ paper: classes.drawer }}
        open={isOpen}
        onClose={toggleDrawer(false)}
      >
        <div
          className={classes.fullList}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <MenuList>
            {reversedRoutes.map((prop, key) => {
              if (prop.sidebarName !== "") {
                return (
                  <NavLink
                    to={prop.path}
                    style={{ textDecoration: "none" }}
                    key={key}
                  >
                    <MenuItem selected={activeRoute(prop.path)}>
                      <ListItemText primary={prop.sidebarName} />
                    </MenuItem>
                  </NavLink>
                );
              }
              return null;
            })}
          </MenuList>
        </div>
      </Drawer>
    </div>
  );
};

export default withRouter(NavigationBar);
