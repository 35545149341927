import { Route, Switch } from "react-router-dom";
import React from "react";
import PatientSearch from "../../patient/PatientSearch";
import PatientView from "../../patient/PatientView";
import ClinicalData from "../../patient/ClinicalData";
import Laboratory from "../../lab/Laboratory";
import BGChart from "../../lab/blood/glucose/Chart";
import Hba1cChart from "../../lab/blood/hba1c/Chart";
import Background from "../../patient/Background";
import MedicalStatus from "../../patient/MedicalStatus";

interface PatientRoutesProps {
  match: {
    path: string;
    url: string;
  };
}

export const PatientRoutes = (props: PatientRoutesProps) => {
  const { path } = props.match;
  return (
    <Switch>
      <Route
        exact
        path={path}
        render={() => <div>Welcome to Patient Routes</div>}
      />
      <Route path={`${path}search`} component={PatientSearch} />
      <Route path={`${path}view`} component={PatientView} />
      <Route path={`${path}clinicaldata`} component={ClinicalData} />
      <Route path={`${path}laboratory`} component={Laboratory} />
      <Route path={`${path}glucose`} component={BGChart} />
      <Route path={`${path}hba1c`} component={Hba1cChart} />
      <Route path={`${path}background`} component={Background} />
      <Route path={`${path}medicalstatus`} component={MedicalStatus} />
    </Switch>
  );
};
