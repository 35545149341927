import React, { useState, useEffect } from "react";
import { getToken } from "../../../../auth/cognito/access";
import { NCapi } from "../../../../api/axios/NCapi";

export function CPT(props) {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const encodedID = encodeURIComponent(props.enc.subject.id.toString());

    const encodedDate = encodeURIComponent(props.enc.period.start.toString());

    getToken().then((token) => {
      NCapi.defaults.headers.common["CognitoJWT"] = token;

      NCapi.get<number>(`encounter/count/${encodedID}/${encodedDate}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          setCount(response.data);
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    });
  });

  let cpt = "99202";
  let date = new Date(props.enc.period.start);
  const day = date.getDay();

  if (day === 2 || day === 4) {
    if (count > 0) {
      cpt = "99213";
    }
  } else if (day === 1) {
    cpt = "99214";
  }

  if (error) {
    return <div>Poverty Level Error</div>;
  }

  if (loading) {
    return "Loading...";
  }

  return <div>{cpt}</div>;
}

export const ParseCPT = (typeList: CodeableConceptDTO[]) => {
  let cpt = "";

  typeList.forEach((type: CodeableConceptDTO) => {
    if (!(typeof type.coding === "undefined")) {
      type.coding.forEach((coding: CodingDTO) => {
        if (coding.system === "http://www.ama-assn.org/go/cpt") {
          cpt = coding.code;
        }
      });
    }
  });

  return cpt;
};
