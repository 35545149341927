export const FormatTitle = (title: string) => {
  let mdRE = /^M\.?D\.?$/;
  let doRE = /^D\.?O\.?$/;
  let paRE = /^P.*A.*$/;
  let npRE = /^N.*P.*$/;
  let cnsRE = /^C.*N.*S.*$/;

  if (mdRE.test(title)) {
    return "MD";
  }
  if (doRE.test(title)) {
    return "DO";
  }
  if (paRE.test(title)) {
    return "PA";
  }
  if (npRE.test(title)) {
    return "NP";
  }
  if (cnsRE.test(title)) {
    return "CNS";
  }

  return "";
};
