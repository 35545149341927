import React from "react";
import clsx from "clsx";
import { IconButton } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import { withStyles, WithStyles } from "@material-ui/core/styles";

interface Props extends WithStyles<typeof styles> {
  children?: React.ReactNode;
  className?: string;
}
// We can inject some CSS into the DOM.
const styles = {
  root: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 25,
    padding: "0 30px",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  },
};

function ReloadButton(props: Props) {
  const { classes, children, className, ...other } = props;

  function refreshPage() {
    window.location.reload(false);
  }

  return (
    <div>
      <IconButton
        className={clsx(classes.root, className)}
        onClick={refreshPage}
        {...other}
      >
        <RefreshIcon />
        Click to Reload
      </IconButton>
    </div>
  );
}

export default withStyles(styles)(ReloadButton);
