import React from "react";
import Nightingale from "../../pages/Nightingale";
import PopulationView from "../../pages/population/PopulationView";
import PopulationDashboard from "../../pages/population/PopulationDashboard";

const Home: React.FC = () => {
  return (
    <div>
      <b>Welcome!</b>
      <p></p>
      <p>This is the health information exchange for</p>
      <h1>{process.env.REACT_APP_ORG_NAME}</h1>
    </div>
  );
};

export interface IRoute {
  path: string;
  sidebarName: string;
  component: React.ComponentType;
}

const Routes = [
  {
    path: "/nightingale",
    sidebarName: "Nightingale",
    component: Nightingale,
  },
  {
    path: "/population/",
    sidebarName: "",
    component: PopulationView,
  },
  {
    path: "/population/dashboard",
    sidebarName: "Population Analysis",
    component: PopulationDashboard,
  },
  {
    path: "/",
    sidebarName: "Home",
    component: Home,
  },
];

export default Routes;
