import { Route, Switch } from "react-router-dom";
import React from "react";
//import PopulationSearch from '../../population/PopulationSearch'
import ICDSearchCard from "../../population/components/ICDSearchCard";
import DxSearch from "../../population/components/DxSearch";
import CentralHealth from "../../population/reports/centralhealth/CentralHealth";
import PopulationDashboard from "../../population/PopulationDashboard";

interface PopulationRoutesProps {
  match: {
    path: string;
    url: string;
  };
}

export const PopulationRoutes = (props: PopulationRoutesProps) => {
  const { path } = props.match;
  return (
    <Switch>
      <Route
        exact
        path={path}
        render={() => <div>Welcome to Population Routes</div>}
      />
      <Route path={`${path}dx_search`} component={DxSearch} />
      <Route path={`${path}icd_search`} component={ICDSearchCard} />
      <Route path={`${path}central_health`} component={CentralHealth} />
      <Route path={`${path}dashboard`} component={PopulationDashboard} />
    </Switch>
  );
};
